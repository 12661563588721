import React, { useEffect } from "react";
import { RouterLink } from "components";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Auth from "../../layouts/Auth";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  cta: {
    textAlign: "center",
    marginTop: theme.spacing(4)
  }
}));

const Logout = () => {
  const classes = useStyles();

  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  return (
    <Auth>
      <div className={classes.root}>
        <Typography align="center" gutterBottom variant="h2">
          Logged out
        </Typography>
        <Typography align="center" gutterBottom>
          You have successfully logged out of the dashboard.
        </Typography>
        <div className={classes.cta}>
          <Button
            color="primary"
            component={RouterLink}
            disableElevation
            to="/login"
            variant="contained"
          >
            Log In Again
          </Button>
        </div>
      </div>
    </Auth>
  );
};

export default Logout;
