import { handleResponse, handleError, authHeader } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/users";

export function getUsers() {
  return fetch(baseUrl, {
    method: "GET",
    headers: { "content-type": "application/json", ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveUser(user) {
  return fetch(baseUrl + "/" + (user._id || ""), {
    method: user._id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(user)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function loginUser(user) {
  return fetch(baseUrl + "/login", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(user)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function profile() {
  return fetch(baseUrl + "/profile", {
    method: "GET",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteUser(userId) {
  return fetch(baseUrl + userId, {
    method: "DELETE",
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
