import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Datetime from "react-datetime";
import moment from "moment";
import {
  getCertificateCustomize,
  getCertificateVerify
} from "../../api/certificateApi";

const CertificateCustomize = () => {
  let location = useLocation();
  const holderDefaults = {
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: ""
  };
  let { id } = useParams();
  const [key, setKey] = useState("");
  const [holder, setHolder] = useState(holderDefaults);
  const [createdOn, setCreatedOn] = useState("");

  useEffect(() => {
    const data = queryString.parse(location.search);
    if (data.key) {
      setKey(data.key ? data.key : "");

      getCertificateVerify(id, data.key)
        .then(res => {
          setHolder(res.holder ? res.holder : holderDefaults);
          setCreatedOn(res.createdOn ? res.createdOn : new Date());
        })
        .catch(err => {
          console.log(err);
          toast.error(err.message);
        });
    } else {
      toast.error("key is required");
    }
  }, [location.search]);

  return (
    <div className="container py-4">
      <h1 className="text-center mb-4 h3">Customize Certificate</h1>
      <Formik
        enableReinitialize
        initialValues={{
          createdOn: createdOn,
          key: key,
          holderName: holder.name,
          holderAddress: holder.address,
          holderCity: holder.city,
          holderProvince: holder.province,
          holderPostalCode: holder.postalCode
        }}
        onSubmit={(values, { setSubmitting }) => {
          getCertificateCustomize(id, values)
            .then(pdf => {
              const fileDownload = require("js-file-download");
              fileDownload(pdf, `${id}.pdf`);
              setSubmitting(false);
            })
            .catch(err => {
              console.log(err);
              toast.error(err.message);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Field
              type="hidden"
              name="key"
              className="form-control"
              value={values.key}
            />
            <div className="form-row justify-content-center">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <p>
                  <small>Field marked with * are required.</small>
                </p>
                <div className="form-group">
                  <label htmlFor="name">Issue Date *</label>
                  <Datetime
                    inputProps={{
                      name: "createdOn",
                      id: "createdOn",
                      autoComplete: "off",
                      placeholder: "MM/DD/YYYY"
                    }}
                    value={
                      values.createdOn &&
                      moment(values.createdOn).format("MM/DD/YYYY")
                    }
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={time => {
                      setFieldValue("createdOn", time ? time : null);
                    }}
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <h2 className="h5 mt-5 mb-2">Certificate Holder</h2>

                <div className="form-group">
                  <label htmlFor="holderName">Name *</label>
                  <Field
                    name="holderName"
                    className="form-control"
                    value={values.holderName}
                  />
                  <ErrorMessage
                    name="holderName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="holderAddress">Address</label>
                  <Field
                    name="holderAddress"
                    className="form-control"
                    value={values.holderAddress}
                  />
                  <ErrorMessage
                    name="holderAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="holderCity">City</label>
                  <Field name="holderCity" className="form-control" />
                  <ErrorMessage
                    name="holderCity"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="holderProvince">Province</label>
                      <Field name="holderProvince" className="form-control" />
                      <ErrorMessage
                        name="holderProvince"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="holderPostalCode">Postal Code</label>
                      <Field name="holderPostalCode" className="form-control" />
                      <ErrorMessage
                        name="holderPostalCode"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className={
                    isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
                  }
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "processing..." : "Download"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CertificateCustomize;
