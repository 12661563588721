import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "./theme";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import { CertificateCustomize } from "./components";
import {
  Certificate,
  CertificateManage,
  Certificates,
  Contacts,
  ContactManage,
  Email,
  Insurers,
  InsurerManage,
  Login,
  Logout,
  PageNotFound,
  Unauthorized
} from "./views";

const App = ({ token }) => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Switch>
        <Route exact path={["/", "/contacts"]} component={Contacts} />
        <Route path="/contact/email" component={Email} />
        <Route path="/contact/:id" component={ContactManage} />
        <Route path="/contact" component={ContactManage} />
        <Route path="/insurers" component={Insurers} />
        <Route path="/insurer/:id" component={InsurerManage} />
        <Route path="/insurer" component={InsurerManage} />
        <Route
          path="/certificates/contact/:contactId"
          component={Certificates}
        />
        <Route path="/certificate/add" component={CertificateManage} />
        <Route
          path="/certificate/update/:id/:copy"
          component={CertificateManage}
        />
        <Route path="/certificate/update/:id" component={CertificateManage} />
        <Route
          path="/certificate/:id/customize"
          component={CertificateCustomize}
        />
        <Route path="/certificate/:id" component={Certificate} />
        <Route path={["/login", "/login"]} component={Login} />
        <Route path={["/logout", "/logout"]} component={Logout} />
        <Route path={["/unauthorized"]} component={Unauthorized} />
        <Route component={PageNotFound} />
      </Switch>
    </ThemeProvider>
  </>
);

function mapStateToProps(state) {
  return {
    token:
      state.userReducer.token === null
        ? localStorage.getItem("user")
        : state.userReducer.token,
    loading: state.apiCallStatusReducer > 0
  };
}

const mapDispatchToProps = {
  //loadUsers: userActions.loadUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
