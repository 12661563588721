import { handleResponse, handleError, authHeader } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/contacts";

export function getContacts() {
  return fetch(baseUrl, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveContact(contact) {
  return fetch(baseUrl + "/" + (contact._id || ""), {
    method: contact._id ? "PUT" : "POST",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(contact)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function emailContact(data) {
  return fetch(`${baseUrl}/email`, {
    method: "POST",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteContact(contactId) {
  return fetch(baseUrl + contactId, {
    method: "DELETE",
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
