import { combineReducers } from "redux";
import certificateReducer from "./certificateReducer";
import userReducer from "./userReducer";
import apiCallStatusReducer from "./apiStatusReducer";

const rootReducer = combineReducers({
  // contactsReducer,
  // insurerReducer,
  userReducer,
  certificateReducer,
  apiCallStatusReducer
});

export default rootReducer;
