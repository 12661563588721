import React, { useEffect } from "react";
import { Auth } from "../../layouts";
import { RouterLink } from "../../components";
import { Button } from "@material-ui/core";

const Unauthorized = () => {
  useEffect(() => {
    window.localStorage.removeItem("user");
  }, []);
  return (
    <Auth>
      <div className="text-center">
        <h1>Access Denied</h1>
        <p>The requested resource is protected. You must login to continue.</p>
        <Button
          color="primary"
          component={RouterLink}
          disableElevation
          to="/login"
          variant="contained"
        >
          Log In
        </Button>
      </div>
    </Auth>
  );
};

export default Unauthorized;
