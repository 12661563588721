import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { getCertificatePdf } from "api/certificateApi";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Grid
} from "@material-ui/core";
import {
  MoreVert as MoreIcon,
  Edit as EditIcon,
  FileCopy as DuplicateIcon,
  PictureAsPdf as PdfIcon
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({}));

const ActionButtons = ({ certificateId, expenseId, ...rest }) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  let history = useHistory();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="More options">
        <IconButton
          {...rest}
          onClick={handleMenuOpen}
          ref={moreRef}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        classes={{ paper: classes.menu }}
        onClose={handleMenuClose}
        open={openMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          onClick={() => {
            history.push(`/certificate/update/${certificateId}`);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Update" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            //onEdit(expenseId);
            history.push(`/certificate/update/${certificateId}/1`);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <DuplicateIcon />
          </ListItemIcon>
          <ListItemText primary="Duplicate" />
        </MenuItem>

        <MenuItem
          onClick={() => {
            getCertificatePdf(certificateId)
              .then(pdf => {
                const fileDownload = require("js-file-download");
                fileDownload(pdf, `${certificateId}.pdf`);
              })
              .catch(error => alert(error));
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <PdfIcon />
          </ListItemIcon>
          <ListItemText primary="Download Pdf" />
        </MenuItem>
      </Menu>
    </>
  );
};

ActionButtons.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  expenseId: PropTypes.number
};

export default ActionButtons;
