import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as certificateActions from "redux/actions/certificateActions";
import { Layout } from "./components";
import PropTypes from "prop-types";
import { Dashboard } from "layouts";

const Certificate = ({
  loadCertificate,
  certificateKey,
  certificateId,
  certificates
}) => {
  useEffect(() => {
    if (!certificates[certificateId]) {
      loadCertificate(certificateId, certificateKey).catch(error => {
        alert("loading certificate failed" + error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dashboard>
      {certificates[certificateId] && (
        <Layout certificate={certificates[certificateId]} />
      )}
    </Dashboard>
  );
};

Certificate.propTypes = {
  certificateId: PropTypes.string.isRequired,
  loadCertificate: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    certificateId: ownProps.match.params.id,
    certificateKey: ownProps.match.params.key,
    certificates: state.certificateReducer.entities.certificates
  };
}

const mapDispatchToProps = {
  loadCertificate: certificateActions.loadCertificate
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
