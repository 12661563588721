import * as Yup from "yup";

const emptyStringToNull = (value, originalValue) => {
  return typeof originalValue === "string" && originalValue.trim() === ""
    ? null
    : value;
};

const schema = Yup.object().shape({
  createdOn: Yup.date().required("Issuing date is required"),
  insured: Yup.string().required(
    "Insured is required. Please select from the suggestions."
  ),
  holder: Yup.string()
    .transform(emptyStringToNull)
    .nullable(
      true
    ) /*.required(
    "Certificate holder is required. Please select from the suggestions."
  )*/,
  description: Yup.string(),
  generalLiability: Yup.object().shape({
    insuranceTypes: Yup.object().shape({
      commercialGeneralLiability: Yup.bool(),
      claimsMade: Yup.bool(),
      claimsOccur: Yup.bool(),
      ownersContractors: Yup.bool(),
      employersLiability: Yup.bool(),
      contingentEmployersLiability: Yup.bool()
    }),
    limits: Yup.object().shape({
      generalAggregate: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      products: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      personal: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      eachOccurrence: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      fireDamage: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      medExpiry: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true)
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        no: Yup.string().required("Policy number is required"),
        effective: Yup.date().required("Policy effective date is required"),
        expiry: Yup.date().required("Policy expiry date is required")
      })
    )
  }),
  automobileLiability: Yup.object().shape({
    insuranceTypes: Yup.object().shape({
      anyAuto: Yup.bool(),
      allOwnedLeasedAutos: Yup.bool(),
      scheduledAutos: Yup.bool(),
      hiredAutos: Yup.bool(),
      nonOwnedAutoTrailerLiability: Yup.bool()
    }),
    limits: Yup.object().shape({
      combinedSingleLimit: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      bodilyInjuryPerPerson: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      bodilyInjuryPerAccident: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      nonOwnedAutoLiability: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true)
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        no: Yup.string().required("Policy number is required"),
        effective: Yup.date().required("Policy effective date is required"),
        expiry: Yup.date().required("Policy expiry date is required")
      })
    )
  }),
  contractorsEquipment: Yup.object().shape({
    insuranceTypes: Yup.object().shape({
      trailerPhysicalDamage: Yup.bool()
    }),
    limits: Yup.object().shape({
      maxAnyoneTrailerACV: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      deductible: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true)
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        no: Yup.string().required("Policy number is required"),
        effective: Yup.date().required("Policy effective date is required"),
        expiry: Yup.date().required("Policy expiry date is required")
      })
    )
  }),
  workersCompensationEmployersLiability: Yup.object().shape({
    insuranceTypes: Yup.object().shape({
      proprietorPartnersExecutiveIncluded: Yup.bool(),
      officersExcluded: Yup.bool(),
      statutoryLimits: Yup.bool(),
      otherLimits: Yup.bool()
    }),
    limits: Yup.object().shape({
      elEachAccident: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      elDiseasePolicyLimit: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      elDiseaseEmployee: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true)
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        no: Yup.string().required("Policy number is required"),
        effective: Yup.date().required("Policy effective date is required"),
        expiry: Yup.date().required("Policy expiry date is required")
      })
    )
  }),
  otherLiability: Yup.object().shape({
    limits: Yup.object().shape({
      broadFormDeductible: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      reeferBreakdownDeductible: Yup.number()
        .transform(emptyStringToNull)
        .nullable(true),
      reeferBreakdownIncluded: Yup.bool()
    }),
    policies: Yup.array().of(
      Yup.object().shape({
        no: Yup.string().required("Policy number is required"),
        effective: Yup.date().required("Policy effective date is required"),
        expiry: Yup.date().required("Policy expiry date is required")
      })
    )
  })
});

export default schema;
