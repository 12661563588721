import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as contactActions from "../../redux/actions/contactActions";
import * as insurerActions from "../../redux/actions/insurerActions";
import * as certificateActions from "../../redux/actions/certificateActions";
import certificateSchema from "./schema";
import { AutoSuggest, CheckboxBoolean } from "components";
import Datetime from "react-datetime";
import merge from "lodash/merge";
import moment from "moment";
import { Dashboard } from "layouts";
import { Grid, Typography } from "@material-ui/core";

const alphaList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

const CertificateManage = ({
  allContacts,
  allInsurers,
  contacts,
  insurers,
  certificate,
  newCertificate,
  currentCertificate,
  loadContacts,
  loadInsurers,
  createCertificate,
  history
}) => {
  useEffect(() => {
    newCertificate();
    if (allContacts.length === 0) {
      loadContacts()
        .then(() => {
          // update insuredSuggester & holder suggester
          // contacts[certificate.insured] && contacts[certificate.insured].name
          /*merge(certificate, {
            insuredSuggester: contacts[certificate.insured]
              ? contacts[certificate.insured].name
              : ""
          });*/
        })
        .catch(err => {
          alert(`Could not load contacts ${err}`);
        });
    }

    if (allInsurers.length === 0) {
      loadInsurers().catch(err => {
        alert(`Could not load Insurers ${err}`);
      });
    }
  }, []);

  useEffect(() => {
    if (currentCertificate) {
      history.push(`/certificate/${currentCertificate}`);
    }
  }, [currentCertificate]);

  const contactOptions = () => {
    return allContacts.map(id => {
      return contacts[id];
    });
  };

  return (
    <Dashboard>
      <Typography variant="h2" gutterBottom>
        {certificate && certificate._id ? `Update` : "Add new certificate"}
      </Typography>
      <Formik
        initialValues={certificate}
        validationSchema={certificateSchema}
        onSubmit={(values, { setSubmitting }) => {
          createCertificate(values)
            .then(certificate => {
              history.push(`/certificate/${certificate.id}`);
            })
            .catch(err => {
              alert(err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form>
            <Field type="hidden" name="insured" className="form-control" />
            <Field type="hidden" name="holder" className="form-control" />

            <div className="p-4 mb-4 bg-white">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="form-group">
                    <label htmlFor="name">Issued On</label>
                    <Datetime
                      inputProps={{
                        name: "createdOn",
                        id: "createdOn",
                        autoComplete: "off",
                        placeholder: "MM/DD/YYYY"
                      }}
                      value={
                        values.createdOn &&
                        moment(values.createdOn).format("MM/DD/YYYY")
                      }
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={time => {
                        setFieldValue("createdOn", time ? time : null);
                      }}
                    />
                    <ErrorMessage
                      name="createdOn"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">
                      Insured {certificate.insuredSuggester}
                    </label>
                    <AutoSuggest
                      id="insuredSuggester"
                      defaultValue={certificate.insuredSuggester}
                      options={contactOptions()}
                      handleSuggestionSelected={(
                        event,
                        { suggestion, method }
                      ) => {
                        if (method === "enter") {
                          event.preventDefault();
                        }
                        setFieldValue("insured", suggestion._id);
                      }}
                    />
                    <ErrorMessage
                      name="insured"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={2}>
                  <div className="form-group">
                    <label htmlFor="name">Certificate Holder Label</label>
                    <Field
                      name={`holderLabel`}
                      placeholder="Layout holder Label"
                      className="form-control"
                    />
                    <ErrorMessage
                      name={`holderLabel`}
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="form-group">
                    <label htmlFor="name">Certificate Holder</label>
                    <AutoSuggest
                      id="holderSuggester"
                      defaultValue={certificate.holderSuggester}
                      options={contactOptions()}
                      handleSuggestionSelected={(
                        event,
                        { suggestion, method }
                      ) => {
                        if (method === "enter") {
                          event.preventDefault();
                        }
                        setFieldValue("holder", suggestion._id);
                      }}
                    />
                    <ErrorMessage
                      name="holder"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx-1 */}

            <Typography variant="h4" gutterBottom>
              Insurers affording coverage
            </Typography>
            <div className="p-4 mb-4 bg-white">
              <FieldArray
                name="insurers"
                render={arrayHelpers => (
                  <div>
                    {values.insurers && values.insurers.length > 0 ? (
                      values.insurers.map((insurer, index) => (
                        <div key={index} className="form-row mb-2">
                          <div className="col-md-4">
                            <Field
                              name={`insurers.${index}`}
                              component="select"
                              className="form-control"
                            >
                              <option></option>
                              {allInsurers.map(id => (
                                <option key={id} value={insurers[id]._id}>
                                  {insurers[id].name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => arrayHelpers.remove(index)} // remove a insurer from the list
                            >
                              -
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        {/* show this when user has removed all friends from the list */}
                        Add an insurer
                      </button>
                    )}
                  </div>
                )}
              />
            </div>
            {/* bx insurers affording coverage */}

            <Typography variant="h4" gutterBottom>
              Coverages
            </Typography>
            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">General Liability</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="generalLiability.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`generalLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.commercialGeneralLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesCommercialGeneralLiability"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="generalLiabilityInsuranceTypesCommercialGeneralLiability"
                        >
                          Commercial General Liability
                        </label>
                      </div>
                      <ul className="list-unstyled ml-3">
                        <li>
                          <div className="form-check">
                            <CheckboxBoolean
                              name="generalLiability.insuranceTypes.claimsMade"
                              value={false}
                              className="form-check-input"
                              id="generalLiabilityInsuranceTypesClaimsMade"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="generalLiabilityInsuranceTypesClaimsMade"
                            >
                              Claims Made
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <CheckboxBoolean
                              name="generalLiability.insuranceTypes.claimsOccur"
                              value={false}
                              className="form-check-input"
                              id="generalLiabilityInsuranceTypesClaimsOccur"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="generalLiabilityInsuranceTypesClaimsOccur"
                            >
                              Claims Occur
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.ownersContractors"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesOwnersContractors"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="generalLiabilityInsuranceTypesOwnersContractors"
                        >
                          Owner &amp; Contractors Prot
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.employersLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesEmployersLiability"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="generalLiabilityInsuranceTypesEmployersLiability"
                        >
                          Employers Liability
                        </label>
                      </div>
                    </li>

                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="generalLiability.insuranceTypes.contingentEmployersLiability"
                          value={false}
                          className="form-check-input"
                          id="generalLiabilityInsuranceTypesContingentEmployersLiability"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="generalLiabilityInsuranceTypesContingentEmployersLiability"
                        >
                          Contingent Employers Liability
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="generalLiability.policies"
                    render={arrayHelpers => (
                      <>
                        {values.generalLiability.policies &&
                        values.generalLiability.policies.length ? (
                          values.generalLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`generalLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `generalLiability.policies.${index}.effective`,
                                        id: `generalLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `generalLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `generalLiability.policies.${index}.expiry`,
                                        id: `generalLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `generalLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`generalLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>Each Occurrence</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.eachOccurrence"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.eachOccurrence"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>General Aggregate</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.generalAggregate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.generalAggregate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Products-Comp/OP AGG</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.products"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.products"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Personal &amp; Adv. Injury</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.personal"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.personal"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label>Fire Damage (Any one fire)</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.fireDamage"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.fireDamage"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Med Exp (Any one person)</label>
                    <Field
                      type="number"
                      name="generalLiability.limits.medExpiry"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="generalLiability.limits.medExpiry"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx general */}

            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">Automobile Liability</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="automobileLiability.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`automobileLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.anyAuto"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesAnyAuto"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="automobileLiabilityInsuranceTypesAnyAuto"
                        >
                          Any Auto
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.allOwnedLeasedAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesAllOwnedLeasedAutos"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="automobileLiabilityInsuranceTypesAllOwnedLeasedAutos"
                        >
                          All Owned/Leased Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.scheduledAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesScheduledAutos"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="automobileLiabilityInsuranceTypesScheduledAutos"
                        >
                          Scheduled Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.hiredAutos"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesHiredAutos"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="automobileLiabilityInsuranceTypesHiredAutos"
                        >
                          Hired Autos
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="automobileLiability.insuranceTypes.nonOwnedAutoTrailerLiability"
                          value={false}
                          className="form-check-input"
                          id="automobileLiabilityInsuranceTypesNonOwnedAutoTrailerLiability"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="automobileLiabilityInsuranceTypesNonOwnedAutoTrailerLiability"
                        >
                          Non-owned Auto/Trailer Liability
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="automobileLiability.policies"
                    render={arrayHelpers => (
                      <>
                        {values.automobileLiability.policies &&
                        values.automobileLiability.policies.length ? (
                          values.automobileLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`automobileLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `automobileLiability.policies.${index}.effective`,
                                        id: `automobileLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `automobileLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `automobileLiability.policies.${index}.expiry`,
                                        id: `automobileLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `automobileLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`automobileLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>Combined Single Limit</label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.combinedSingleLimit"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.combinedSingleLimit"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Bodily Injury <small>Per person</small>
                    </label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.bodilyInjuryPerPerson"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.bodilyInjuryPerPerson"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Bodily Injury <small>Per accident</small>
                    </label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.bodilyInjuryPerAccident"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.bodilyInjuryPerAccident"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Non-owned auto liability</label>
                    <Field
                      type="number"
                      name="automobileLiability.limits.nonOwnedAutoLiability"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="automobileLiability.limits.nonOwnedAutoLiability"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx automobile liability*/}

            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">Contractors Equipment</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="contractorsEquipment.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`contractorsEquipment.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="contractorsEquipment.insuranceTypes.trailerPhysicalDamage"
                          value={false}
                          className="form-check-input"
                          id="contractorsEquipmentInsuranceTypesTrailerPhysicalDamage"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="contractorsEquipmentInsuranceTypesTrailerPhysicalDamage"
                        >
                          Trailer Interchange Physical Damage, Includes Coverage
                          to Non-owned trailers, containers and chassis.
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="contractorsEquipment.policies"
                    render={arrayHelpers => (
                      <>
                        {values.contractorsEquipment.policies &&
                        values.contractorsEquipment.policies.length ? (
                          values.contractorsEquipment.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`contractorsEquipment.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `contractorsEquipment.policies.${index}.effective`,
                                        id: `contractorsEquipmentPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `contractorsEquipment.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `contractorsEquipment.policies.${index}.expiry`,
                                        id: `contractorsEquipmentPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `contractorsEquipment.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`contractorsEquipment.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>Limit</label>
                    <Field
                      type="number"
                      name="contractorsEquipment.limits.maxAnyoneTrailerACV"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="contractorsEquipment.limits.maxAnyoneTrailerACV"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Deductible</label>
                    <Field
                      type="number"
                      name="contractorsEquipment.limits.deductible"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="contractorsEquipment.limits.deductible"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx contractors equipment*/}

            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">
                Workers Compensation &amp; Employers Liability
              </h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="workersCompensationEmployersLiability.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`workersCompensationEmployersLiability.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.proprietorPartnersExecutiveIncluded"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesProprietorPartnersExecutiveIncluded"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesProprietorPartnersExecutiveIncluded"
                        >
                          The Proprietor/Partners/Executive (INCL)
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.officersExcluded"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesOfficersExcluded"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesOfficersExcluded"
                        >
                          Officers Are (EXCL)
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.statutoryLimits"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesStatutoryLimits"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesStatutoryLimits"
                        >
                          Statutory Limits
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <CheckboxBoolean
                          name="workersCompensationEmployersLiability.insuranceTypes.otherLimits"
                          value={false}
                          className="form-check-input"
                          id="workersCompensationEmployersLiabilityInsuranceTypesOtherLimits"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="workersCompensationEmployersLiabilityInsuranceTypesOtherLimits"
                        >
                          Other
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="workersCompensationEmployersLiability.policies"
                    render={arrayHelpers => (
                      <>
                        {values.workersCompensationEmployersLiability
                          .policies &&
                        values.workersCompensationEmployersLiability.policies
                          .length ? (
                          values.workersCompensationEmployersLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`workersCompensationEmployersLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `workersCompensationEmployersLiability.policies.${index}.effective`,
                                        id: `workersCompensationEmployersLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `workersCompensationEmployersLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `workersCompensationEmployersLiability.policies.${index}.expiry`,
                                        id: `workersCompensationEmployersLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `workersCompensationEmployersLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`workersCompensationEmployersLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>EL each accident</label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elEachAccident"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elEachAccident"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>EL Disease - Policy limit</label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elDiseasePolicyLimit"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elDiseasePolicyLimit"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>EL Disease-Each Employee</label>
                    <Field
                      type="number"
                      name="workersCompensationEmployersLiability.limits.elDiseaseEmployee"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="workersCompensationEmployersLiability.limits.elDiseaseEmployee"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx workers compensation */}

            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">Motor Truck Cargo</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="motorTruckCargo.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`motorTruckCargo.insurer.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="motorTruckCargo.policies"
                    render={arrayHelpers => (
                      <>
                        {values.motorTruckCargo.policies &&
                        values.motorTruckCargo.policies.length ? (
                          values.motorTruckCargo.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`motorTruckCargo.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `motorTruckCargo.policies.${index}.effective`,
                                        id: `motorTruckCargoPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `motorTruckCargo.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `motorTruckCargo.policies.${index}.expiry`,
                                        id: `motorTruckCargoPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `motorTruckCargo.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`motorTruckCargo.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>Limit</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.eachOccurrence"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.eachOccurrence"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Deductible</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.broadFormDeductible"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.broadFormDeductible"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Reefer breakdown Ded</label>
                    <Field
                      type="number"
                      name="motorTruckCargo.limits.reeferBreakdownDeductible"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="motorTruckCargo.limits.reeferBreakdownDeductible"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-check">
                    <CheckboxBoolean
                      name="motorTruckCargo.limits.reeferBreakdownIncluded"
                      value={false}
                      className="form-check-input"
                      id="motorTruckCargoLimitsReeferBreakdownIncluded"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="motorTruckCargoLimitsReeferBreakdownIncluded"
                    >
                      Refer Breakdown Included
                    </label>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx motor truck cargo */}

            <div className="p-4 mb-4 bg-white">
              <h3 className="text-uppercase mb-3">Other</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>INS. LTR</label>
                    <Field
                      name="otherLiability.insurer"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option key={`otherLiability.insurer.${id}`} value={id}>
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label>Other Liability name</label>
                    <Field
                      type="text"
                      name="otherLiability.insuranceTypes.custom"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.insuranceTypes.custom"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>INS. LTR (2)</label>
                    <Field
                      name="otherLiability.insurer2"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      {alphaList.map(id => (
                        <option
                          key={`otherLiability.insurer2.${id}`}
                          value={id}
                        >
                          {id}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label>Other Liability (2) name</label>
                    <Field
                      type="text"
                      name="otherLiability.insuranceTypes.custom2"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.insuranceTypes.custom2"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="otherLiability.policies"
                    render={arrayHelpers => (
                      <>
                        {values.otherLiability.policies &&
                        values.otherLiability.policies.length ? (
                          values.otherLiability.policies.map(
                            (policy, index) => (
                              <div key={index}>
                                <div className="form-row mb-2">
                                  <div className="col-md-3">
                                    <Field
                                      name={`otherLiability.policies[${index}].no`}
                                      placeholder="Policy number"
                                      className="form-control"
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.no`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `otherLiability.policies.${index}.effective`,
                                        id: `otherLiabilityPolicies${index}Effective`,
                                        autoComplete: "off",
                                        placeholder: "Effective MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.effective &&
                                        moment(policy.effective).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `otherLiability.policies.${index}.effective`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.effective`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Datetime
                                      inputProps={{
                                        name: `otherLiability.policies.${index}.expiry`,
                                        id: `otherLiabilityPolicies${index}Expiry`,
                                        autoComplete: "off",
                                        placeholder: "Expiry MM/DD/YYYY"
                                      }}
                                      value={
                                        policy.expiry &&
                                        moment(policy.expiry).format(
                                          "MM/DD/YYYY"
                                        )
                                      }
                                      dateFormat="MM/DD/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={time => {
                                        setFieldValue(
                                          `otherLiability.policies.${index}.expiry`,
                                          time ? time : null
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`otherLiability.policies.${index}.expiry`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </button>{" "}
                                    <button
                                      type="button"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          no: "",
                                          effective: "",
                                          expiry: ""
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() =>
                              arrayHelpers.push({
                                no: "",
                                effective: null,
                                expiry: null
                              })
                            }
                          >
                            Add policy
                          </button>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="form-group">
                    <label>Limit</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.limit"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.limit"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Deductible</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.deductible"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.deductible"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label>Limit (2)</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.limit2"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.limit2"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label>Deductible (2)</label>
                    <Field
                      type="number"
                      name="otherLiability.limits.deductible2"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="otherLiability.limits.deductible2"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* bx other */}

            <div className="form-group">
              <label htmlFor="description">
                Description of Operations/Locations/Vehicles (Attach ACORD 101,
                Additional Remarks Schedule, If more space is required)
              </label>
              <Field
                name="description"
                component="textarea"
                className="form-control"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <CheckboxBoolean
                  name="isCustomizable"
                  value={false}
                  className="form-check-input"
                  id="isCustomizable"
                />
                <label className="form-check-label" htmlFor="isCustomizable">
                  Make pdf certificate editable (users with link shall be able
                  to change the date and certificate holders)
                </label>
              </div>
            </div>

            <button
              type="submit"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </button>
          </Form>
        )}
      </Formik>
    </Dashboard>
  );
};

CertificateManage.propTypes = {};

const getCertificateById = (certificates, id, createCopy, contacts) => {
  let defaults = {
    createdOn: "",
    insured: "",
    insuredSuggester: "",
    holderLabel: "CERTIFICATE HOLDER",
    holder: null,
    holderSuggester: "",
    insurers: [""],
    description: "",
    generalLiability: {
      insurer: "",
      insuranceTypes: {
        commercialGeneralLiability: false,
        claimsMade: false,
        claimsOccur: false,
        ownersContractors: false,
        employersLiability: false,
        contingentEmployersLiability: false
      },
      limits: {
        generalAggregate: "",
        products: "",
        personal: "",
        eachOccurrence: "",
        fireDamage: "",
        medExpiry: ""
      },
      policies: []
    },
    automobileLiability: {
      insurer: "",
      insuranceTypes: {
        anyAuto: false,
        allOwnedLeasedAutos: false,
        scheduledAutos: false,
        hiredAutos: false,
        nonOwnedAutoTrailerLiability: false
      },
      limits: {
        combinedSingleLimit: "",
        bodilyInjuryPerPerson: "",
        bodilyInjuryPerAccident: "",
        nonOwnedAutoLiability: ""
      },
      policies: []
    },
    contractorsEquipment: {
      insurer: "",
      insuranceTypes: {
        trailerPhysicalDamage: false
      },
      limits: {
        maxAnyoneTrailerACV: "",
        deductible: ""
      },
      policies: []
    },
    workersCompensationEmployersLiability: {
      insurer: "",
      insuranceTypes: {
        proprietorPartnersExecutiveIncluded: false,
        officersExcluded: false,
        statutoryLimits: false,
        otherLimits: false
      },
      limits: {
        elEachAccident: "",
        elDiseasePolicyLimit: "",
        elDiseaseEmployee: ""
      },
      policies: []
    },
    motorTruckCargo: {
      insurer: "",
      limits: {
        eachOccurrence: "",
        broadFormDeductible: "2500",
        reeferBreakdownDeductible: "",
        reeferBreakdownIncluded: false
      },
      policies: []
    },
    otherLiability: {
      insurer: "",
      insurer2: "",
      insuranceTypes: {
        custom: ""
      },
      limits: {
        limit: "",
        deductible: ""
      },
      policies: []
    }
  };

  if (id && certificates && certificates[id]) {
    merge(defaults, certificates[id], {
      insuredSuggester: contacts[certificates[id].insured]
        ? contacts[certificates[id].insured].name
        : "",
      holderSuggester: contacts[certificates[id].holder]
        ? contacts[certificates[id].holder].name
        : ""
    });

    if (createCopy) {
      delete defaults["_id"];
      return defaults;
    } else {
      return defaults;
    }
  } else {
    return defaults;
  }
};

function mapStateToProps(state, ownProps) {
  return {
    certificate: getCertificateById(
      state.certificateReducer.entities.certificates,
      ownProps.match.params.id,
      ownProps.match.params.copy,
      state.certificateReducer.entities.contacts
    ),
    allContacts: state.certificateReducer.allContacts,
    allInsurers: state.certificateReducer.allInsurers,
    contacts: state.certificateReducer.entities.contacts,
    insurers: state.certificateReducer.entities.insurers,
    currentContact: state.certificateReducer.currentContact,
    currentCertificate: state.certificateReducer.currentCertificate
  };
}

const mapDispatchToProps = {
  newCertificate: certificateActions.newCertificate,
  loadContacts: contactActions.loadContacts,
  loadInsurers: insurerActions.loadInsurers,
  createCertificate: certificateActions.createCertificate
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateManage);
