import * as types from "../actions/actionTypes";
import { normalize } from "normalizr";
import { certificateSchema, contactsSchema, insurersSchema } from "../schemas";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";

function normalizeCertificate(data) {
  return normalize(data, certificateSchema);
}

export default function certificateReducer(
  state = {
    entities: {
      contacts: {},
      insurers: {},
      certificates: {}
    },
    result: [],
    allCertificates: [],
    allContacts: [],
    allInsurers: [],
    currentContact: null,
    currentCertificate: null
  },
  action
) {
  let normalizedData, mergedData;
  switch (action.type) {
    //######################
    // Certificates
    case types.NEW_CERTIFICATE:
      return merge({}, state, {
        currentCertificate: null
      });
    case types.CREATE_CERTIFICATE_SUCCESS:
      return merge({}, state, {
        entities: {
          certificates: {
            [action.certificate._id]: action.certificate
          }
        },
        allCertificates: [action.certificate._id, ...state.allCertificates],
        currentCertificate: action.certificate._id
      });
    case types.UPDATE_CERTIFICATE_SUCCESS:
      return merge({}, state, {
        entities: {
          certificates: {
            [action.certificate._id]: action.certificate
          }
        },
        currentCertificate: action.certificate._id
      });
    case types.LOAD_CERTIFICATES_SUCCESS:
      normalizedData = normalize(action.certificates, [certificateSchema]);
      mergedData = merge({}, state, normalizedData);
      return {
        ...mergedData,
        allCertificates: normalizedData.result,
        currentContact: action.contactId
      };
    case types.LOAD_CERTIFICATE_SUCCESS:
      normalizedData = normalize(action.certificate, certificateSchema);
      mergedData = merge({}, state, normalizedData);
      return {
        ...mergedData,
        //allCertificates: normalizedData.result,
        currentContact: action.contactId
      };
    /*return merge({}, state, {
                entities: {
                    certificates: {
                        [action.certificate._id]: action.certificate
                    }
                },
                allCertificates: [
                    ...state.allCertificates,
                    action.certificate._id
                ]
            });*/

    //###############
    // contacts
    case types.CREATE_CONTACT_SUCCESS:
      return merge({}, state, {
        entities: {
          contacts: {
            [action.contact._id]: action.contact
          }
        },
        allContacts: [...state.allContacts, action.contact._id]
      });
    case types.UPDATE_CONTACT_SUCCESS:
      return merge({}, state, {
        entities: {
          contacts: {
            [action.contact._id]: action.contact
          }
        }
      });
    case types.LOAD_CONTACTS_SUCCESS:
      normalizedData = normalize(action.contacts, [contactsSchema]);
      //return { ...normalizedData, allContacts: normalizedData.result };
      return merge({}, state, normalizedData, {
        allContacts: normalizedData.result
      });

    //##################
    // insurers
    case types.CREATE_INSURER_SUCCESS:
      return merge({}, state, {
        entities: {
          insurers: {
            [action.insurer._id]: action.insurer
          }
        },
        allInsurers: [...state.allInsurers, action.insurer._id]
      });
    case types.UPDATE_INSURER_SUCCESS:
      return merge({}, state, {
        entities: {
          insurers: {
            [action.insurer._id]: action.insurer
          }
        }
      });
    case types.LOAD_INSURERS_SUCCESS:
      normalizedData = normalize(action.insurers, [insurersSchema]);
      //return { ...normalizedData, allInsurers: normalizedData.result };
      return merge({}, state, normalizedData, {
        allInsurers: normalizedData.result
      });
    default:
      return state;
  }
}
