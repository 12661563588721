import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  }
}));

const Auth = ({ children }) => {
  const classes = useStyles();

  return <div className={clsx(classes.root)}>{children}</div>;
};

export default Auth;
