import * as types from "./actionTypes";
import * as contactApi from "../../api/contactApi";
import { apiCallError, beginApiCall } from "./apiStatusActions";

export function createContactSuccess(contact) {
  return { type: types.CREATE_CONTACT_SUCCESS, contact };
}

export function updateContactSuccess(contact) {
  return { type: types.UPDATE_CONTACT_SUCCESS, contact };
}

export function loadContactsSuccess(contacts) {
  return { type: types.LOAD_CONTACTS_SUCCESS, contacts };
}

export function createContact(contact) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return contactApi
      .saveContact(contact)
      .then(res => {
        contact._id
          ? dispatch(updateContactSuccess(res))
          : dispatch(createContactSuccess(res));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadContacts() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return contactApi
      .getContacts()
      .then(contacts => {
        dispatch(loadContactsSuccess(contacts));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
