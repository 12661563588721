import React from "react";
import { Auth } from "../../layouts";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Auth>
      <div className="textCenter">
        <h1>Oops! page not found.</h1>
        <p>Sorry the page you are looking for does not exists.</p>

        <Button
          color="primary"
          component={RouterLink}
          disableElevation
          to="/"
          variant="contained"
        >
          Back to Home
        </Button>
      </div>
    </Auth>
  );
};

export default PageNotFound;
