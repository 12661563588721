import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import useRouter from "utilities/useRouter";
import {
  AppBar,
  Button,
  Hidden,
  Toolbar,
  IconButton,
  Input
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Input as InputIcon,
  Menu as MenuIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  logo: {
    fontSize: "1.1rem",
    color: theme.palette.white,
    textDecoration: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit"
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit"
    }
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const TopBar = ({ onOpenNavBarMobile, className }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <AppBar className={clsx(className, classes.root)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Link to="/" className={clsx(classes.logo)}>
          Scottroad Ins.
        </Link>
        <div className={clsx(classes.flexGrow)} />
        <Hidden smDown>
          <Formik
            initialValues={{ search: "" }}
            onSubmit={values => {
              router.history.push(`/contacts?search=${values.search}`);
            }}
          >
            {({ handleChange, handleBlur, values }) => (
              <Form>
                <div className={clsx(classes.search)}>
                  <SearchIcon className={clsx(classes.searchIcon)} />
                  <Input
                    className={clsx(classes.searchInput)}
                    disableUnderline
                    name="search"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Search contacts"
                    value={values.name}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Hidden>
        <Button
          className={classes.logoutButton}
          color="inherit"
          component={RouterLink}
          to="/logout"
        >
          <InputIcon className={classes.logoutIcon} />
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func.isRequired
};

export default TopBar;
