import React, { useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import * as contactActions from "redux/actions/contactActions";
import PropTypes from "prop-types";
import { Header, Results } from "./components";
import { Dashboard } from "layouts";
import { Spinner, RouterLink } from "components";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Contacts = ({ allContacts, contacts, loadContacts, loading }) => {
  let location = useLocation();
  const [search, setSearch] = useState("");
  const [contactsFiltered, setContactsFiltered] = useState(null);

  const handleSearchSubmit = evt => {
    evt.preventDefault();

    let tempContactsFiltered = allContacts.filter(key => {
      if (contacts[key].name.toLowerCase().includes(search)) {
        return key;
      }
    });
    setContactsFiltered(tempContactsFiltered);
  };

  const filterContacts = filter => {
    let tempContactsFiltered = allContacts;

    if (filter) {
      tempContactsFiltered = allContacts.filter(key => {
        if (contacts[key].name.toLowerCase().includes(filter)) {
          return key;
        }
      });
    }

    setContactsFiltered(tempContactsFiltered);
  };

  useEffect(() => {
    let queryParams = queryString.parse(location.search);

    if (allContacts.length === 0) {
      loadContacts().catch(error => {
        alert("loading contacts failed" + error);
      });
    }
  }, []);

  useEffect(() => {
    let queryParams = queryString.parse(location.search);
    filterContacts(queryParams.search && queryParams.search.toLowerCase());
  }, [allContacts.length, location]);

  return (
    <Dashboard>
      <Header />
      {loading ? (
        <Spinner />
      ) : (
        <Results
          allContacts={contactsFiltered ? contactsFiltered : allContacts}
          contacts={contacts}
          loading={loading}
        />
      )}
    </Dashboard>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.object.isRequired,
  loadContacts: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    allContacts: state.certificateReducer.allContacts,
    contacts: state.certificateReducer.entities.contacts,
    loading: state.apiCallStatusReducer > 0
  };
}

const mapDispatchToProps = {
  loadContacts: contactActions.loadContacts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
