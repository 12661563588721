import { handleResponse, handleError, authHeader } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/certificates";

export function getCertificates(contactId) {
  return fetch(`${baseUrl}/contact/${contactId}`, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCertificate(id) {
  return fetch(`${baseUrl}/${id}`, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCertificateVerify(id, key) {
  return fetch(`${baseUrl}/${id}/verify?key=${key}`, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCertificatePdf(id) {
  return fetch(`${baseUrl}/pdf/${id}`, {
    headers: { ...authHeader() }
  })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error("Network response was not ok...");
      }
    })
    .catch(handleError);
}

export function saveCertificate(certificate) {
  return fetch(baseUrl + "/" + (certificate._id || ""), {
    method: certificate._id ? "PUT" : "POST",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(certificate)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCertificateCustomize(id, payload) {
  return fetch(`${baseUrl}/customize/${id}`, {
    method: "POST",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        console.log(response);
        throw new Error(
          response.statusText
            ? response.statusText
            : "Network response was not ok."
        );
      }
    })
    .catch(handleError);
}

export function deleteCertificate(certificateId) {
  return fetch(baseUrl + certificateId, {
    method: "DELETE",
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
