import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as insurerActions from "redux/actions/insurerActions";
import PropTypes from "prop-types";
import { Spinner } from "components";
import { Dashboard } from "layouts";
import { Header, Results } from "./components";

const Insurers = ({ insurers, allInsurers, loadInsurers, loading }) => {
  useEffect(() => {
    if (allInsurers.length === 0) {
      loadInsurers().catch(error => {
        alert("loading insurers failed" + error);
      });
    }
  }, [allInsurers.length]);

  return (
    <Dashboard>
      <Header />
      {loading ? (
        <Spinner />
      ) : (
        <Results insurers={insurers} allInsurers={allInsurers} />
      )}
    </Dashboard>
  );
};

Insurers.propTypes = {
  loadInsurers: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    insurers: state.certificateReducer.entities.insurers,
    allInsurers: state.certificateReducer.allInsurers,
    loading: state.apiCallStatusReducer > 0
  };
}

const mapDispatchToProps = {
  loadInsurers: insurerActions.loadInsurers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insurers);
