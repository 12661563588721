import React from "react";
import BoxIcon from "./box.jpg";
import BoxTickIcon from "./box-tick.jpg";

const CheckBox = ({ value, children }) => {
  return (
    <div className="d-flex">
      <div className="mr-2">
        {value ? <img src={BoxTickIcon} /> : <img src={BoxIcon} />}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default CheckBox;
