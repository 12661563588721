import * as types from "./actionTypes";
import * as insurerApi from "../../api/insurerApi";
import { apiCallError, beginApiCall } from "./apiStatusActions";

export function createInsurerSuccess(insurer) {
  return { type: types.CREATE_INSURER_SUCCESS, insurer };
}

export function updateInsurerSuccess(insurer) {
  return { type: types.UPDATE_INSURER_SUCCESS, insurer };
}

export function loadInsurersSuccess(insurers) {
  return { type: types.LOAD_INSURERS_SUCCESS, insurers };
}

export function createInsurer(insurer) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return insurerApi
      .saveInsurer(insurer)
      .then(res => {
        insurer._id
          ? dispatch(updateInsurerSuccess(res))
          : dispatch(createInsurerSuccess(res));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadInsurers() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return insurerApi
      .getInsurers()
      .then(insurers => {
        dispatch(loadInsurersSuccess(insurers));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
