import * as types from "./actionTypes";
import * as certificateApi from "../../api/certificateApi";
import { apiCallError, beginApiCall } from "./apiStatusActions";

export function createCertificateSuccess(certificate) {
  return { type: types.CREATE_CERTIFICATE_SUCCESS, certificate };
}

export function updateCertificateSuccess(certificate) {
  return { type: types.UPDATE_CERTIFICATE_SUCCESS, certificate };
}

export function loadCertificatesSuccess(certificates, contactId) {
  return { type: types.LOAD_CERTIFICATES_SUCCESS, certificates, contactId };
}

export function loadCertificateSuccess(certificate) {
  return { type: types.LOAD_CERTIFICATE_SUCCESS, certificate };
}

export function newCertificateCreator() {
  return { type: types.NEW_CERTIFICATE };
}

export function newCertificate() {
  return function(dispatch) {
    dispatch(newCertificateCreator());
  };
}

export function createCertificate(certificate) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return certificateApi
      .saveCertificate(certificate)
      .then(res => {
        if (certificate._id) {
          dispatch(updateCertificateSuccess(res));
          return res;
        } else {
          dispatch(createCertificateSuccess(res));
          return res;
        }
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadCertificates(contactId) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return certificateApi
      .getCertificates(contactId)
      .then(certificates => {
        dispatch(loadCertificatesSuccess(certificates, contactId));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadCertificate(id) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return certificateApi
      .getCertificate(id)
      .then(certificate => {
        dispatch(loadCertificateSuccess(certificate));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
