import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import * as userActions from "../../redux/actions/userActions";
import { Auth } from "layouts";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  card: {
    //maxWidth: theme.breakpoints.values.sm
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  formControl: {
    minWidth: "100%"
  }
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("email is required"),
  password: Yup.string().required("Password is required")
});

const Login = ({ logoutUser, loginUser, history }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    logoutUser();
  }, []);

  return (
    <Auth>
      <div className="w-full max-w-sm">
        <h1 className="text-4xl font-bold mb-6">LogIn</h1>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { setSubmitting }) => {
            loginUser(values)
              .then(user => {
                history.push(`/contacts`);
              })
              .catch(err => {
                console.log(err);
                toast.error("There was an error in response");
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            isSubmitting,
            errors,
            values,
            handleChange,
            handleBlur,
            touched
          }) => (
            <Form>
              <Field type="hidden" name="plan" className="form-control" />

              <div className="mb-4">
                <TextField
                  className={classes.formControl}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </div>

              <div className="mb-4">
                <FormControl
                  className={classes.formControl}
                  error={errors.password && touched.password}
                  variant="outlined"
                >
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    className={classes.formControl}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    variant="outlined"
                  />
                </FormControl>
              </div>

              <Button
                color="primary"
                disabled={isSubmitting}
                disableElevation
                type="submit"
                variant="contained"
              >
                {isSubmitting ? "loading.." : "Log In"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

function mapStateToProps(state) {
  return {
    plans: state.userReducer
  };
}

const mapDispatchToProps = {
  logoutUser: userActions.logoutUser,
  loginUser: userActions.loginUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
