import * as types from "./actionTypes";
import * as userApi from "../../api/userApi";
import { apiCallError, beginApiCall } from "./apiStatusActions";

export function createUserSuccess(user) {
  return { type: types.CREATE_USER_SUCCESS, user };
}

export function updateUserSuccess(user) {
  return { type: types.UPDATE_USER_SUCCESS, user };
}

export function logoutUser() {
  return { type: types.LOGOUT_USER };
}

export function loginUserSuccess(token) {
  return { type: types.LOGIN_USER_SUCCESS, token };
}

export function loadUserProfileSuccess(profile) {
  return { type: types.LOAD_USER_PROFILE_SUCCESS, profile };
}

export function createUser(user) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return userApi
      .saveUser(user)
      .then(res => {
        user._id
          ? dispatch(updateUserSuccess(res))
          : dispatch(createUserSuccess(res));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loginUser(user) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return userApi
      .loginUser(user)
      .then(res => {
        dispatch(loginUserSuccess(res));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserProfile() {
  return function(dispatch) {
    dispatch(beginApiCall());
    return userApi
      .profile()
      .then(res => {
        dispatch(loadUserProfileSuccess(res));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
