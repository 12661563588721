import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as contactActions from "../../redux/actions/contactActions";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Dashboard } from "layouts";

const PlansSchema = Yup.object().shape({
  name: Yup.string().required("Contact name is required"),
  address: Yup.string(),
  city: Yup.string(),
  province: Yup.string(),
  postalCode: Yup.string()
    .min(5, "Postal Code should be minimum 5 characters long")
    .max(8, "Postal Code should not be larger than 8 characters"),
  phone: Yup.string().length(10, "Phone number should be 10 digits long"),
  email: Yup.string().email("Please enter a valid email address")
});

const ContactManage = ({ contacts, contact, createContact, history }) => {
  return (
    <Dashboard>
      <h2>
        {contact && contact._id ? `Update ${contact.name}` : "Add new contact"}
      </h2>
      <Formik
        initialValues={contact}
        validationSchema={PlansSchema}
        onSubmit={(values, { setSubmitting }) => {
          createContact(values)
            .then(() => {
              history.push("/contacts");
            })
            .catch(err => {
              alert(err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <Form>
            <div className="form-row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <Field type="text" name="address" className="form-control" />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <Field type="text" name="city" className="form-control" />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="province">Province</label>
                  <Field
                    name="province"
                    component="select"
                    className="form-control"
                  >
                    <option value=""></option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="NU">Nunavut</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="YT">Yukon</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="AA">Armed Forces America</option>
                    <option value="AE">Armed Forces Europe</option>
                    <option value="AP">Armed Forces Pacific</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Field>
                  <ErrorMessage
                    name="province"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="postalCode">Postal Code</label>
                  <Field
                    type="text"
                    name="postalCode"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="postalCode"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <Field type="text" name="phone" className="form-control" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field type="text" name="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </button>
          </Form>
        )}
      </Formik>
    </Dashboard>
  );
};

ContactManage.propTypes = {
  contacts: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  createContact: PropTypes.func.isRequired
};

export function getContactById(contacts, id) {
  //return plans.find(plan => plan._id === id) || null;
  if (id && contacts.entities && contacts.entities.contacts) {
    return contacts.entities.contacts[id];
  }

  return {
    id: "",
    name: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    email: ""
  };
}

function mapStateToProps(state, ownProps) {
  return {
    contact: getContactById(state.certificateReducer, ownProps.match.params.id),
    contacts: state.certificateReducer
  };
}

const mapDispatchToProps = {
  createContact: contactActions.createContact,
  loadContacts: contactActions.loadContacts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactManage);
