import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as insurerActions from "redux/actions/insurerActions";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Dashboard } from "layouts";

const InsurerSchema = Yup.object().shape({
  name: Yup.string().required("Insurer name is required")
});

const InsurerManage = ({ insurers, insurer, createInsurer, history }) => {
  return (
    <Dashboard>
      <h2>{insurer && insurer._id ? `Update Insurer` : "Add new insurer"}</h2>
      <Formik
        initialValues={insurer}
        validationSchema={InsurerSchema}
        onSubmit={(values, { setSubmitting }) => {
          createInsurer(values)
            .then(() => {
              history.push("/insurers");
            })
            .catch(err => {
              alert(err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <Field type="text" name="name" className="form-control" />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger"
              />
            </div>

            <button
              type="submit"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving" : "Save"}
            </button>
          </Form>
        )}
      </Formik>
    </Dashboard>
  );
};

InsurerManage.propTypes = {
  insurers: PropTypes.object.isRequired,
  insurer: PropTypes.object.isRequired,
  createInsurer: PropTypes.func.isRequired
};

export function getInsurerById(insurers, id) {
  if (id && insurers.entities && insurers.entities.insurers) {
    return insurers.entities.insurers[id];
  }

  return {
    id: "",
    name: ""
  };
}

function mapStateToProps(state, ownProps) {
  return {
    insurer: getInsurerById(state.certificateReducer, ownProps.match.params.id),
    insurers: state.certificateReducer
  };
}

const mapDispatchToProps = {
  createInsurer: insurerActions.createInsurer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsurerManage);
