import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { getCertificatePdf } from "api/certificateApi";

const Results = ({ insurers, allInsurers }) => (
  <>
    {allInsurers && allInsurers.length ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Insurers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allInsurers.map(id => (
              <TableRow key={id}>
                <TableCell>
                  <Link component={RouterLink} to={`/insurer/${id}`}>
                    {insurers[id].name}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div>
        0 insurer.{" "}
        <Link component={RouterLink} to="/insurer">
          add new insurer
        </Link>
      </div>
    )}
  </>
);

Results.propTypes = {
  certificates: PropTypes.object.isRequired,
  allCertificates: PropTypes.array
};

export default Results;
