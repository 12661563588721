import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Suggest from "react-autosuggest";
import * as Yup from "yup";
import * as contactActions from "../../redux/actions/contactActions";
import { connect } from "react-redux";
import queryString from "query-string";
import { emailContact } from "../../api/contactApi";
import { Dashboard } from "layouts";

const emailSchema = Yup.object().shape({
  to: Yup.string().required("To is required"),
  subject: Yup.string().required("subject is required"),
  body: Yup.string().required("email content is required")
});

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = suggestion => <div>{suggestion}</div>;

const Email = ({ contacts, allContacts, loadContacts, queryString }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [opt, setOpt] = useState([]);
  const [emailStatus, setEmailStatus] = useState(null);

  useEffect(() => {
    if (allContacts.length === 0) {
      loadContacts().catch(err => {
        alert(`Could not load contacts ${err}`);
      });
    }

    setOpt(allContacts.map(id => contacts[id].email));
  }, [allContacts.length]);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : opt.filter(
          val => val.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
  };

  return (
    <Dashboard>
      <h4>Email</h4>
      {(() => {
        if (emailStatus) {
          return (
            <div className="alert alert-success mb-3">
              Status:: {emailStatus}
            </div>
          );
        }
      })()}
      <Formik
        initialValues={{
          certificateId: queryString.cId ? queryString.cId : "",
          to: queryString.email || "",
          subject: queryString.subject ? queryString.subject : "",
          body: queryString.cId
            ? `Hi,
Please find your certificate attached with this email.
Should you have any questions, please contact us.
`
            : "Your message"
        }}
        validationSchema={emailSchema}
        onSubmit={(values, { setSubmitting }) => {
          emailContact(values)
            .then(res => {
              if (res.message) {
                setEmailStatus(res.message);
              }
            })
            .catch(err => {
              console.log(err);
              alert(`Error sending email:: ${err}`);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form autocomplete="off">
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name">To</label>
                  <Field type="text" name="to" className="form-control" />
                  <div className="text-muted f-14">
                    Separate multiple emails with comma.
                  </div>
                  <ErrorMessage
                    name="to"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label htmlFor="name">Search contact email records</label>
                  <Suggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={
                      handleSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      handleSuggestionsClearRequested
                    }
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={{
                      value,
                      onChange: handleChange,
                      className: "form-control"
                    }}
                    onSuggestionSelected={(event, { suggestion, method }) => {
                      if (method === "enter") {
                        event.preventDefault();
                      }
                      setFieldValue("to", suggestion);
                    }}
                    id={`email`}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <Field type="text" name="subject" className="form-control" />
              <ErrorMessage
                name="subject"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="body">Message</label>
              <Field
                component="textarea"
                rows="5"
                name="body"
                className="form-control"
              />
              <ErrorMessage
                name="body"
                component="div"
                className="text-danger"
              />
              <p className="text-info mt-3">
                In case the certificate is customizable, its link will be
                automatically added to the above email.
              </p>
            </div>

            <button
              type="submit"
              className={
                isSubmitting ? "btn btn-primary loading" : "btn btn-primary"
              }
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending" : "Send"}
            </button>
          </Form>
        )}
      </Formik>
    </Dashboard>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    allContacts: state.certificateReducer.allContacts,
    contacts: state.certificateReducer.entities.contacts,
    queryString: queryString.parse(ownProps.location.search)
  };
}

const mapDispatchToProps = {
  loadContacts: contactActions.loadContacts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Email);
