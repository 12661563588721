import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import * as contactActions from "redux/actions/contactActions";
import * as insurerActions from "redux/actions/insurerActions";
import moment from "moment";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { Button, IconButton, Paper, Typography } from "@material-ui/core";
import { ActionButtons, CheckBox } from "./components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Layout = ({
  certificate,
  contacts,
  allContacts,
  insurers,
  allInsurers,
  user,
  loadContacts,
  loadInsurers
}) => {
  useEffect(() => {
    if (allContacts.length === 0) {
      loadContacts().catch(err => {
        alert(`Could not load contacts ${err}`);
      });
    }

    if (allInsurers.length === 0) {
      loadInsurers().catch(err => {
        alert(`Could not load insurers ${err}`);
      });
    }
  }, []);

  return (
    <>
      <div className="flex justify-between mb-6">
        <div className="flex items-center">
          <div className="me-3">
            <IconButton
              color="primary"
              aria-label="back to certificates"
              component={RouterLink}
              to={`/certificates/contact/${certificate.insured}`}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <Typography component="h1" variant="h2">
            Certificate
          </Typography>
        </div>

        <div className="flex items-center">
          <span className="me-1">
            <Button
              color="primary"
              component={RouterLink}
              to={`/contact/email?email=${
                contacts[certificate.insured].email
              }&cId=${certificate._id}&subject=Cargo Certificate`}
              variant="contained"
            >
              Email
            </Button>
          </span>
          <ActionButtons certificateId={certificate._id} />
        </div>
      </div>

      <div className="p-4 bg-white">
        {certificate.isCustomizable ? (
          <Typography color="primary">
            Certificate is Customizable (users with link shall be able to edit
            date and certificate holder
          </Typography>
        ) : (
          ""
        )}
        <table className="border w-full">
          <tbody>
            <tr>
              <td>
                <h2 className="m-0 p-0">Certificate of Liability Insurance</h2>
              </td>
              <td>
                <div className="f-14">DATE</div>
                <strong>
                  {moment(certificate.createdOn).format("DD MMM YYYY")}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="border w-full">
          <tbody>
            <tr>
              <td className="text-sm p-2">
                This certificate is issued as a matter of information only and
                confers no rights upon the certificate holder. This certificate
                does not affirmatively or negatively amend, extend or alter the
                coverage afforded by the policies below. This certificate of
                insurance does not constitute a contract between the issuing
                insurer(s), authorized representative or producer, and the
                certificate holder.
              </td>
            </tr>
            <tr>
              <td className="text-sm p-2">
                IMPORTANT: If the certificate holder is an ADDITIONAL INSURED,
                the policy(ies) must be endorsed. If SUBROGATION IS WAIVED,
                subject to the terms and conditions of the policy, certain
                policies may require an endorsement. A statement on this
                certificate does not confer rights to the certificate holder in
                lieu of such endorsement(s).
              </td>
            </tr>
          </tbody>
        </table>

        <table className="border w-full">
          <tbody>
            <tr>
              <td width="50%">
                <div className="p-2">
                  <div>
                    <strong>BROKER</strong>
                  </div>
                  SCOTT ROAD INSURANCE SERVICES LTD.
                  <br />
                  Unit 107 7130 120 street
                  <br />
                  Surrey BC V3W 3M8
                  <br />
                  Ph (604)597-2700, Fax (604) 597-2900
                </div>
              </td>
              <td rowSpan="2" className="align-top">
                <table className="bordered p-2">
                  <tbody>
                    {certificate.insurers.map((key, index) => {
                      return (
                        <tr key={key}>
                          <td className="text-center line-height-1">
                            <div className="f-12">INSURER</div>
                            <strong>{String.fromCharCode(index + 65)}</strong>
                          </td>
                          <td>{insurers[key] && insurers[key].name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <div className="p-2">
                  <div>
                    <strong>INSURED</strong>
                  </div>
                  {contacts[certificate.insured].name}
                  <br />
                  {contacts[certificate.insured].address}
                  <br />
                  {contacts[certificate.insured].city}{" "}
                  {contacts[certificate.insured].province}{" "}
                  {contacts[certificate.insured].postalCode}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="p-2">
          <strong>COVERAGES</strong>
        </div>
        <div className="border w-full text-sm p-2">
          This is to certify that the policies of insurance listed below have
          been issued to the insured named above for the policy period
          indicated, notwithstanding any requirements, terms or condition or any
          contract or other document with respect to which this certificate may
          be issued or may pertain, the insurance afforded by the policies
          described herein is subject to all the terms, exclusions and
          conditions of such policies, limits shown may have been reduced by
          paid claims.
        </div>

        <table className="border w-full">
          <tbody>
            <tr>
              <td width="33%">
                <strong className="f-14">TYPE OF INSURANCE</strong>
              </td>
              <td width="11%">
                <strong className="f-14">POLICY NUMBER</strong>
              </td>
              <td width="11%">
                <strong className="f-14">POLICY EFFECTIVE</strong>
              </td>
              <td width="11%">
                <strong className="f-14">POLICY EXPIRY</strong>
              </td>
              <td width="34%">
                <strong className="f-14">LIMITS</strong>
                <div className="f-12">
                  **All policy limits are in Canadian Funds**
                </div>
              </td>
            </tr>
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>GENERAL LIABILITY</strong>
                  {certificate.generalLiability.insurer && (
                    <>{` (${certificate.generalLiability.insurer})`}</>
                  )}
                </div>
                <ul className="list-unstyled f-14 mb-0">
                  <li>
                    <CheckBox
                      value={
                        certificate.generalLiability.insuranceTypes
                          .commercialGeneralLiability
                      }
                    >
                      COMMERCIAL GENERAL LIABILITY
                    </CheckBox>
                    <ul className="list-unstyled ml-3 mb-0">
                      <li>
                        <CheckBox
                          value={
                            certificate.generalLiability.insuranceTypes
                              .claimsMade
                          }
                        >
                          CLAIMS MADE
                        </CheckBox>
                      </li>
                      <li>
                        <CheckBox
                          value={
                            certificate.generalLiability.insuranceTypes
                              .claimsOccur
                          }
                        >
                          OCCUR
                        </CheckBox>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.generalLiability.insuranceTypes
                          .ownersContractors
                      }
                    >
                      OWNER'S &amp; CONTRACTORS PROT
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.generalLiability.insuranceTypes
                          .employersLiability
                      }
                    >
                      EMPLOYERS LIABILITY
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.generalLiability.insuranceTypes
                          .contingentEmployersLiability
                      }
                    >
                      CONTINGENT EMPLOYERS LIABILITY{" "}
                      <small className="d-block">
                        (WITH RESPECT TO CDN OPS)
                      </small>
                    </CheckBox>
                  </li>
                </ul>
              </td>
              <td>
                {certificate.generalLiability.policies.map((policy, index) => (
                  <div key={index}>{policy.no}</div>
                ))}
              </td>
              <td>
                {certificate.generalLiability.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.effective).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td>
                {certificate.generalLiability.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.expiry).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td>EACH OCCURRENCE</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.generalLiability.limits.eachOccurrence
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">GENERAL AGGREGATE</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.generalLiability.limits.generalAggregate
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>PRODUCTS-COMP/OP AGG</td>
                      <td>
                        <NumberFormat
                          value={certificate.generalLiability.limits.products}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>PERSONAL &amp; ADV INJURY</td>
                      <td>
                        <NumberFormat
                          value={certificate.generalLiability.limits.personal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>FIRE DAMAGE (Any one fire)</td>
                      <td>
                        <NumberFormat
                          value={certificate.generalLiability.limits.fireDamage}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>MED EXP (Any one person)</td>
                      <td>
                        <NumberFormat
                          value={certificate.generalLiability.limits.medExpiry}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* general liability*/}
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>AUTOMOBILE LIABILITY</strong>
                  {certificate.automobileLiability.insurer && (
                    <>{` (${certificate.automobileLiability.insurer})`}</>
                  )}
                </div>
                <ul className="list-unstyled f-14 mb-0">
                  <li>
                    <CheckBox
                      value={
                        certificate.automobileLiability.insuranceTypes.anyAuto
                      }
                    >
                      ANY AUTO
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.automobileLiability.insuranceTypes
                          .allOwnedLeasedAutos
                      }
                    >
                      ALL OWNED/LEASED AUTOS
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.automobileLiability.insuranceTypes
                          .scheduledAutos
                      }
                    >
                      SCHEDULED AUTOS
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.automobileLiability.insuranceTypes
                          .hiredAutos
                      }
                    >
                      HIRED AUTOS
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.automobileLiability.insuranceTypes
                          .nonOwnedAutoTrailerLiability
                      }
                    >
                      NON-OWNED AUTO/TRAILER LIABILITY
                    </CheckBox>
                  </li>
                </ul>
              </td>
              <td>
                {certificate.automobileLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>{policy.no}</div>
                  )
                )}
              </td>
              <td>
                {certificate.automobileLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.effective).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td>
                {certificate.automobileLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.expiry).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td width="70%">COMBINED SINGLE LIMIT</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.automobileLiability.limits
                              .combinedSingleLimit
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BODILY INJURY <small>(Per person)</small>
                      </td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.automobileLiability.limits
                              .bodilyInjuryPerPerson
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        BODILY INJURY <small>(Per accident)</small>
                      </td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.automobileLiability.limits
                              .bodilyInjuryPerAccident
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>NON-OWNED AUTO LIABILITY</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.automobileLiability.limits
                              .nonOwnedAutoLiability
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* AUTOMOBILE LIABILITY */}
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>CONTRACTORS EQUIPMENT</strong>
                  {certificate.contractorsEquipment.insurer && (
                    <>{` (${certificate.contractorsEquipment.insurer})`}</>
                  )}
                </div>
                <ul className="list-unstyled f-14 mb-0">
                  <li>
                    <CheckBox
                      value={
                        certificate.contractorsEquipment.insuranceTypes
                          .trailerPhysicalDamage
                      }
                    >
                      TRAILER INTERCHANGE PHYSICAL DAMAGE,{" "}
                      <span className="f-12">
                        INCLUDES COVERAGE TO NON-OWNED TRAILERS, CONTAINERS AND
                        CHASSIS
                      </span>
                      .
                    </CheckBox>
                  </li>
                </ul>
              </td>
              <td>
                {certificate.contractorsEquipment.policies.map(
                  (policy, index) => (
                    <div key={index}>{policy.no}</div>
                  )
                )}
              </td>
              <td>
                {certificate.contractorsEquipment.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.effective).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td>
                {certificate.contractorsEquipment.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.expiry).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td width="70%">LIMIT</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.contractorsEquipment.limits
                              .maxAnyoneTrailerACV
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DEDUCTIBLE</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.contractorsEquipment.limits.deductible
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* CONTRACTOR LIABILITY */}
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>WORKER COMPENSATION AND EMPLOYERS LIABILITY</strong>
                  {certificate.workersCompensationEmployersLiability
                    .insurer && (
                    <>{` (${certificate.workersCompensationEmployersLiability.insurer})`}</>
                  )}
                </div>
                <ul className="list-unstyled f-14 mb-0">
                  <li>
                    <CheckBox
                      value={
                        certificate.workersCompensationEmployersLiability
                          .insuranceTypes.proprietorPartnersExecutiveIncluded
                      }
                    >
                      THE PROPRIETOR/PARTNERS/EXECUTIVE (INC)
                    </CheckBox>
                  </li>
                  <li>
                    <CheckBox
                      value={
                        certificate.workersCompensationEmployersLiability
                          .insuranceTypes.officersExcluded
                      }
                    >
                      OFFICERS ARE (EXCL)
                    </CheckBox>
                  </li>
                </ul>
              </td>
              <td>
                {certificate.workersCompensationEmployersLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>{policy.no}</div>
                  )
                )}
              </td>
              <td>
                {certificate.workersCompensationEmployersLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.effective).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td>
                {certificate.workersCompensationEmployersLiability.policies.map(
                  (policy, index) => (
                    <div key={index}>
                      {moment(policy.expiry).format("DD MMM YYYY")}
                    </div>
                  )
                )}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td width="70%">
                        <ul className="list-unstyled pl-0 mb-0 mb-0">
                          <li>
                            <CheckBox
                              value={
                                certificate
                                  .workersCompensationEmployersLiability
                                  .insuranceTypes.statutoryLimits
                              }
                            >
                              STATUTORY LIMITS
                            </CheckBox>
                          </li>
                          <li>
                            <CheckBox
                              value={
                                certificate
                                  .workersCompensationEmployersLiability
                                  .insuranceTypes.otherLimits
                              }
                            >
                              OTHER
                            </CheckBox>
                          </li>
                        </ul>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>EL EACH ACCIDENT</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.workersCompensationEmployersLiability
                              .limits.elEachAccident
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>EL DISEASE - POLICY LIMIT</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.workersCompensationEmployersLiability
                              .limits.elDiseasePolicyLimit
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>EL DISEASE - EA EMPLOYEE</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.workersCompensationEmployersLiability
                              .limits.elDiseaseEmployee
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* WORKERS COMPENSATION */}
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>MOTOR TRUCK CARGO</strong>
                  {certificate.motorTruckCargo.insurer && (
                    <>{` (${certificate.motorTruckCargo.insurer})`}</>
                  )}
                </div>
              </td>
              <td>
                {certificate.motorTruckCargo.policies.map((policy, index) => (
                  <div key={index}>{policy.no}</div>
                ))}
              </td>
              <td>
                {certificate.motorTruckCargo.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.effective).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td>
                {certificate.motorTruckCargo.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.expiry).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td width="70%">LIMIT</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.motorTruckCargo.limits.eachOccurrence
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">DEDUCTIBLE</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.motorTruckCargo.limits
                              .broadFormDeductible
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>REEFER BREAKDOWN DED</td>
                      <td>
                        <NumberFormat
                          value={
                            certificate.motorTruckCargo.limits
                              .reeferBreakdownDeductible
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    {(() => {
                      if (
                        certificate.motorTruckCargo.limits
                          .reeferBreakdownIncluded === true
                      ) {
                        return (
                          <tr>
                            <td>REFER BREAKDOWN</td>
                            <td>INCLUDED</td>
                          </tr>
                        );
                      }
                    })()}
                  </tbody>
                </table>
              </td>
            </tr>
            {/* MOTOR TRUCK CARGO */}
            <tr>
              <td className="align-top">
                <div className="mb-1">
                  <strong>OTHER</strong>
                </div>
                <div>
                  {certificate.otherLiability.insuranceTypes.custom}
                  {certificate.otherLiability.insurer && (
                    <>{` (${certificate.otherLiability.insurer})`}</>
                  )}
                </div>
                <div>
                  {certificate.otherLiability.insuranceTypes.custom2}
                  {certificate.otherLiability.insurer2 && (
                    <>{` (${certificate.otherLiability.insurer2})`}</>
                  )}
                </div>
              </td>
              <td>
                {certificate.otherLiability.policies.map((policy, index) => (
                  <div key={index}>{policy.no}</div>
                ))}
              </td>
              <td>
                {certificate.otherLiability.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.effective).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td>
                {certificate.otherLiability.policies.map((policy, index) => (
                  <div key={index}>
                    {moment(policy.expiry).format("DD MMM YYYY")}
                  </div>
                ))}
              </td>
              <td className="p-0">
                <table className="table-values">
                  <tbody>
                    <tr>
                      <td width="70%">LIMIT</td>
                      <td>
                        <NumberFormat
                          value={certificate.otherLiability.limits.limit}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DEDUCTIBLE</td>
                      <td>
                        <NumberFormat
                          value={certificate.otherLiability.limits.deductible}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="70%">LIMIT</td>
                      <td>
                        <NumberFormat
                          value={certificate.otherLiability.limits.limit2}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DEDUCTIBLE</td>
                      <td>
                        <NumberFormat
                          value={certificate.otherLiability.limits.deductible2}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* OTHER */}
          </tbody>
        </table>

        <div className="border w-full">
          <div className="mb-1 p-2">
            DESCRIPTION OF OPERATIONS/LOCATIONS/VEHICLES (Attach ACORD 101,
            Additional Remarks Schedule, If more space is required)
          </div>
          <div>{certificate.description}</div>
        </div>

        <table className="border w-full">
          <tbody>
            <tr>
              <td width="50%">
                <strong>{certificate.holderLabel}</strong>
              </td>
              <td width="50%">
                <strong>CANCELLATION</strong>
              </td>
            </tr>
            <tr>
              <td className="align-top">
                {(() => {
                  if (contacts[certificate.holder]) {
                    return (
                      <>
                        {contacts[certificate.holder].name}
                        <br />
                        {contacts[certificate.holder].address}
                        <br />
                        {contacts[certificate.holder].city}{" "}
                        {contacts[certificate.holder].province}{" "}
                        {contacts[certificate.holder].postalCode}
                      </>
                    );
                  }
                })()}
              </td>
              <td>
                <p>
                  Should any of the above described policies be cancelled before
                  the expiration date thereof, notice will be delivered in
                  accordance with the policy provisions. 30 day notice of
                  cancellation
                </p>
                <p>AUTHORIZED REPRESENTATIVE</p>
                <p>
                  <img
                    src={certificate.user && certificate.user.signatureUrl}
                    alt={certificate.user && certificate.user.name}
                  />
                </p>
                <div>{certificate.user && certificate.user.name}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

Layout.propTypes = {
  certificate: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.userReducer,
    certificate: ownProps.certificate,
    contacts: state.certificateReducer.entities.contacts,
    allContacts: state.certificateReducer.allContacts,
    insurers: state.certificateReducer.entities.insurers,
    allInsurers: state.certificateReducer.allInsurers
  };
}

const mapDispatchToProps = {
  loadContacts: contactActions.loadContacts,
  loadInsurers: insurerActions.loadInsurers
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
