import React, { useEffect, useState } from "react";
import Suggest from "react-autosuggest";

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

const AutoSuggest = ({
  options,
  handleSuggestionSelected,
  id,
  defaultValue
}) => {
  //const dataValuesArray = _values(dataValues);
  const [value, setValue] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {}, []);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : options.filter(
          val => val.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
  };

  return (
    <Suggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        value,
        onChange: handleChange,
        className: "form-control"
      }}
      id={id}
      onSuggestionSelected={handleSuggestionSelected}
    />
  );
};

export default AutoSuggest;
