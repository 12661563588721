import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { TopBar, NavBar } from "./components";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  topBar: {
    zIndex: 2,
    position: "relative"
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto"
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3)
    }
  }
}));

const Dashboard = ({ children }) => {
  const classes = useStyle();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      history.push("/login");
    }
  }, []);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={clsx(classes.root)}>
      <TopBar
        className={clsx(classes.topBar)}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={clsx(classes.container)}>
        <NavBar
          className={clsx(classes.navBar)}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <div className={clsx(classes.content)}>{children}</div>
      </div>
    </div>
  );
};

export default Dashboard;
