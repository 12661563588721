import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

const Header = () => {
  return (
    <>
      <div className="flex justify-between mb-6 items-center">
        <Typography component="h1" variant="h2">
          Certificates
        </Typography>
        <Button
          color="primary"
          component={RouterLink}
          to="/certificate/add"
          variant="contained"
        >
          Add new certificate
        </Button>
      </div>
    </>
  );
};

export default Header;
