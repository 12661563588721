import { handleResponse, handleError, authHeader } from "./apiUtils";
const baseUrl = process.env.REACT_APP_API_URL + "/insurers";

export function getInsurers() {
  return fetch(baseUrl, {
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveInsurer(insurer) {
  return fetch(baseUrl + "/" + (insurer._id || ""), {
    method: insurer._id ? "PUT" : "POST",
    headers: { "content-type": "application/json", ...authHeader() },
    body: JSON.stringify(insurer)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteInsurer(insurerId) {
  return fetch(baseUrl + insurerId, {
    method: "DELETE",
    headers: { ...authHeader() }
  })
    .then(handleResponse)
    .catch(handleError);
}
