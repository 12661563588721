/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import GroupIcon from "@material-ui/icons/Group";
import SecurityIcon from "@material-ui/icons/Security";

export default [
  {
    title: "Pages",
    pages: [
      {
        title: "Insurers",
        href: "/insurers",
        icon: SecurityIcon
      },
      {
        title: "Contacts",
        href: "/contacts",
        icon: GroupIcon
      }
    ]
  }
];
