import { schema } from "normalizr";

const usersSchema = new schema.Entity("users", {}, { idAttribute: "_id" });
const contactsSchema = new schema.Entity(
    "contacts",
    {},
    { idAttribute: "_id" }
);
const insurersSchema = new schema.Entity(
    "insurers",
    {},
    { idAttribute: "_id" }
);
const certificateSchema = new schema.Entity(
    "certificates",
    {
        insured: contactsSchema,
        holder: contactsSchema,
        insurers: [insurersSchema]
    },
    { idAttribute: "_id" }
);

export { usersSchema, contactsSchema, certificateSchema, insurersSchema };
