import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Hidden, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import useRouter from "utilities/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowY: "auto"
  },
  content: {
    padding: theme.spacing(2)
  }
}));

const NavBar = ({ openMobile, onMobileClose, className, ...rest }) => {
  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const navBarContent = (
    <div className={clsx(classes.content)}>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          variant="temporary"
          onClose={onMobileClose}
          open={openMobile}
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navBarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper className={clsx(classes.root, className)} elevation={1} square>
          {navBarContent}
        </Paper>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
