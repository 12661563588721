import * as types from "../actions/actionTypes";
// import { normalize } from "normalizr";
// import { usersSchema } from "../schemas";
import merge from "lodash/merge";
// import union from "lodash/union";

// function normalizePlan(data) {
//     return normalize(data, usersSchema);
// }

export default function userReducer(
  state = { profile: null, token: null },
  action
) {
  switch (action.type) {
    case types.LOGOUT_USER:
      localStorage.removeItem("user");
      return { profile: null, token: null };
    case types.LOGIN_USER_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.token));
      return merge({}, state, {
        token: action.token
      });
    case types.LOAD_USER_PROFILE_SUCCESS:
      return merge({}, state, { profile: action.profile });
    default:
      return state;
  }
}
