import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

const Header = () => {
  return (
    <>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h2">
            Contacts
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            component={RouterLink}
            to="/contact"
            variant="contained"
          >
            Add contact
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body2" gutterBottom>
        Add each Insured and Certificate Holders as contacts.
      </Typography>
    </>
  );
};

export default Header;
