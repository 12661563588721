import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";

const Results = ({ allContacts, contacts }) => (
  <>
    {allContacts.length ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allContacts.map(key => (
              <TableRow key={key}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/certificates/contact/${key}`}
                  >
                    {contacts[key].name}
                  </Link>
                </TableCell>
                <TableCell>
                  {contacts[key].address}, {contacts[key].city}{" "}
                  {contacts[key].province} {contacts[key].postalCode}
                </TableCell>
                <TableCell>{contacts[key].phone}</TableCell>
                <TableCell>
                  <a href={`mailto:${contacts[key].email}`}>
                    {contacts[key].email}
                  </a>
                </TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`/contact/${key}`}>
                    Update
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div>
        0 contacts. <Link to="/contact">add new contact</Link>
      </div>
    )}
  </>
);

Results.propTypes = {
  allContacts: PropTypes.array.isRequired,
  contacts: PropTypes.object.isRequired
};

export default Results;
