import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

const Header = () => {
  return (
    <>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h2">
            Insurers
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            component={RouterLink}
            to="/insurer"
            variant="contained"
          >
            Add insurer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
