// contacts
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const LOAD_CONTACTS_SUCCESS = "LOAD_CONTACTS_SUCCESS";

// insurers
export const CREATE_INSURER_SUCCESS = "CREATE_INSURER_SUCCESS";
export const UPDATE_INSURER_SUCCESS = "UPDATE_INSURER_SUCCESS";
export const LOAD_INSURERS_SUCCESS = "LOAD_INSURERS_SUCCESS";

// certificates
export const CREATE_CERTIFICATE_SUCCESS = "CREATE_CERTIFICATE_SUCCESS";
export const UPDATE_CERTIFICATE_SUCCESS = "UPDATE_CERTIFICATE_SUCCESS";
export const LOAD_CERTIFICATE_SUCCESS = "LOAD_CERTIFICATE_SUCCESS";
export const LOAD_CERTIFICATES_SUCCESS = "LOAD_CERTIFICATES_SUCCESS";
export const NEW_CERTIFICATE = "NEW_CERTIFICATE";

// users
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";

// misc
export const BEGIN_API_CALL = "BEGIN_API_CALL";

// api
export const API_CALL_ERROR = "API_CALL_ERROR";
