import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as certificateActions from "../../redux/actions/certificateActions";
import PropTypes from "prop-types";
import { Spinner } from "components";
import { Header, Results } from "./components";
import { Dashboard } from "layouts";

const Certificates = ({
  certificates,
  allCertificates,
  contactId,
  contacts,
  currentContact,
  loadCertificates,
  loading
}) => {
  useEffect(() => {
    if (contactId !== currentContact) {
      loadCertificates(contactId).catch(error => {
        alert("loading certificates failed" + error);
      });
    }
  }, [contactId, currentContact]);

  return (
    <Dashboard>
      <Header />
      <div className="cnt">
        {loading ? (
          <Spinner />
        ) : (
          <Results
            contacts={contacts}
            certificates={certificates}
            allCertificates={allCertificates}
          />
        )}
      </div>
    </Dashboard>
  );
};

Certificates.propTypes = {
  contactId: PropTypes.string.isRequired,
  loadCertificates: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    contactId: ownProps.match.params.contactId,
    contacts: state.certificateReducer.entities.contacts,
    allCertificates: state.certificateReducer.allCertificates,
    certificates: state.certificateReducer.entities.certificates,
    loading: state.apiCallStatusReducer > 0,
    currentContact: state.certificateReducer.currentContact
  };
}

const mapDispatchToProps = {
  loadCertificates: certificateActions.loadCertificates
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
