import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { getCertificatePdf } from "api/certificateApi";

const Results = ({ certificates, allCertificates, contacts }) => (
  <>
    {allCertificates && allCertificates.length ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Issued On - Insured / Holder</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allCertificates.map(id => (
              <TableRow key={id}>
                <TableCell>
                  <Link component={RouterLink} to={`/certificate/${id}`}>
                    {moment(certificates[id].createdOn).format("MMM DD, YYYY")}{" "}
                    - <strong>{contacts[certificates[id].insured].name}</strong>{" "}
                    /{" "}
                    {contacts[certificates[id].holder] &&
                      contacts[certificates[id].holder].name}
                  </Link>
                </TableCell>
                <TableCell>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      getCertificatePdf(certificates[id]._id)
                        .then(pdf => {
                          const fileDownload = require("js-file-download");
                          fileDownload(pdf, `${certificates[id]._id}.pdf`);
                        })
                        .catch(error => alert(error));
                    }}
                  >
                    Download PDF
                  </a>
                  {" | "}
                  <Link
                    component={RouterLink}
                    to={`/contact/email?email=${contacts[certificates[id].insured].email}&cId=${id}&subject=Cargo Certificate`}
                  >
                    Email
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div>
        0 certificate.{" "}
        <Link component={RouterLink} to="/certificate/add">
          add new certificate
        </Link>
      </div>
    )}
  </>
);

Results.propTypes = {
  certificates: PropTypes.object.isRequired,
  allCertificates: PropTypes.array
};

export default Results;
